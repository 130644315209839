<template>
  <div class="events-list" :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'profileReceivedEvents' || $route.name === 'profileSentEvents'}]">
    <draggable-dynamic-table ref="eventsListTable"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="$route.name === 'Profile'"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getEvents"
                             @row:clicked="showEvent($event)"
                             v-model="data"/>

    <!-- show event -->
    <custom-event ref="event"
                  :event-data="selectedEvent"
                  @seen="showEventStatus = false"
                  :seen-event-status="pageType === 'received'"/>

    <!--  insert event prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertEventPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertEventBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('events.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertEventPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <insert-event/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <button v-show="false"
            id="InsertEventBTN"
            @click="insertEventPromptStatus = true"/>
  </div>
</template>

<script>
import axios from 'axios'
import CustomIcon from '../../../../components/customIcon/customIcon'
import CustomEvent from '../../../../components/customEvent/customEvent'
import InsertEvent from '../../events/insert/insertEvent'
import {getProfileReceivedEvents, getProfileSentEvents} from '../../../../http/requests/users/users'

export default {
  name: 'profileEvents',
  components: {InsertEvent, CustomEvent, CustomIcon},
  metaInfo () {
    return {
      title: this.type === 'received' ? this.$t('profile.events.receive.title') : this.$t('profile.events.send.title')
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      pageType: 'received',
      insertEventPromptStatus: false,
      showEventStatus: false,
      types: {
        1: this.$t('events.eventTypes.update'),
        3: this.$t('events.eventTypes.message'),
        5: this.$t('events.eventTypes.report'),
      },
      priority: {
        1: this.$t('events.priority.veryLow'),
        2: this.$t('events.priority.low'),
        3: this.$t('events.priority.medium'),
        4: this.$t('events.priority.high'),
        5: this.$t('events.priority.veryHigh')
      },
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'createdAt',
          i18n: 'profile.events.list.table.header.createdAt',
          width: 'calc(((100% / 10 )))',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterType: 'date',
          sortable: true,
          locked: true
        },
        {
          field: 'priority',
          i18n: 'profile.events.list.table.header.priority',
          width: 'calc(((100% / 10 )))',
          minWidth: 150,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('events.priority.all'),
              value: '-1'
            },
            {
              label: this.$t('events.priority.veryLow'),
              value: '1'
            },
            {
              label: this.$t('events.priority.low'),
              value: '2'
            },
            {
              label: this.$t('events.priority.medium'),
              value: '3'
            },
            {
              label: this.$t('events.priority.high'),
              value: '4'
            },
            {
              label: this.$t('events.priority.veryHigh'),
              value: '5'
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        {
          field: 'type',
          i18n: 'profile.events.list.table.header.type',
          width: 'calc(((100% / 10 )))',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('events.eventTypes.all'),
              value: '-1'
            },
            {
              label: this.$t('events.eventTypes.update'),
              value: '1'
            },
            {
              label: this.$t('events.eventTypes.activity'),
              value: '2'
            },
            {
              label: this.$t('events.eventTypes.message'),
              value: '3'
            },
            {
              label: this.$t('events.eventTypes.warning'),
              value: '4'
            },
            {
              label: this.$t('events.eventTypes.report'),
              value: '5'
            }
          ],
          minWidth: 150,
          sortable: true
          // locked: true,
        },
        {
          field: 'seen',
          i18n: 'profile.events.list.table.header.seen',
          width: 'calc(((100% / 10 )))',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('profile.events.seenTypes.all'),
              value: '-1'
            },
            {
              label: this.$t('profile.events.seenTypes.seen'),
              value: '1'
            },
            {
              label: this.$t('profile.events.seenTypes.unSeen'),
              value: '0'
            }
          ],
          minWidth: 150,
          sortable: true
          // locked: true,
        },
        {
          field: 'content',
          i18n: 'profile.events.list.table.header.content',
          width: 'calc(((100% / 10 )) * 2)',
          minWidth: 300,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        {
          field: 'subject',
          i18n: 'profile.events.list.table.header.subject',
          width: 'calc(((100% / 10 )))',
          minWidth: 300,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        {
          field: 'receiver',
          i18n: 'profile.events.list.table.header.receiver',
          width: 'calc(((100% / 10 )) * 2)',
          minWidth: 200,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        // {
        //   field: 'creator',
        //   i18n: 'profile.events.list.table.header.creator',
        //   width: 'calc(((100% / 10 )) * 2)',
        //   minWidth: 200,
        //   filter: true,
        //   filterTypes: [
        //     {
        //       icon: 'search',
        //       name: 'شامل شود',
        //       i18n: 'draggableTable.filter.types.search',
        //       id: 0
        //     }
        //   ],
        //   align: 'center',
        //   sortable: true
        //   // locked: true,
        // },
        {
          field: 'rowNumber',
          i18n: 'profile.events.list.table.header.rowNumber',
          // sortable: true,
          // locked: true,
          align: 'center',
          width: '70px',
          minWidth: 70,
          sortable: true,
          footer: {}
        }
      ],
      selectedEvent: {},
      filters: {},
      sorts: [],
      data: [],
      page: 1,
      total_count: null,
      loadingTimer: 0,
      actions: [
        {
          toolbar: [
            // {
            //   id: 'InsertEventBTN',
            //   color: 'success',
            //   icon: 'PLUS',
            //   iconPack: 'useral',
            //   permission: 'event.create'
            // }
          ],
          leftToolbar: [
            {
              id: 'settingTable',
              icon: 'SETTING',
              iconPack: 'useral'
            },
            {
              id: {name: 'Profile'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    const columnsLabelFields = this.columnsLabel.map((column) => { return column.field })
    if (this.type) this.pageType = this.type

    if (this.$route.name !== 'profileReceivedEvents' && this.type !== 'received') {
      this.pageType = 'sent'
      this.$store.dispatch('setPageTitle', this.$t('profile.events.send.title'))
      this.columnsLabel.splice(columnsLabelFields.indexOf('creator'), 1)
    } else {
      this.$store.dispatch('setPageTitle', this.$t('profile.events.receive.title'))
      this.columnsLabel.splice(columnsLabelFields.indexOf('receiver'), 1)
    }


    if (this.pageType !== 'sent') {
      delete this.actions[0].toolbar
    }

    this.$nextTick(() => {
      this.$store.dispatch('auth/setAccessToken')
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    this.getEvents()
  },
  methods: {
    getEvents () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (this.data.length < this.total_count || !this.total_count) {
          if (this.$refs.eventsListTable && this.data.length === 0) this.$refs.eventsListTable.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.eventsListTable && this.data.length > 0) this.$refs.eventsListTable.loadMoreStatus = 'Loading'

          this.getFiltersStatus = true
          if (this.pageType === 'received') {
            getProfileReceivedEvents(this.page, this.filters, this.sorts).then((response) => {
              const events = response.data

              events.data.forEach((event) => {
                this.data.push({
                  id: event.event.id,
                  rowNumber: this.data.length + 1,
                  receiver: `${event.user.name} ${event.user.family}(${event.user.role.name})`,
                  // creator: `${event.event.creator.name} ${event.event.creator.family}(${event.event.creator.role.name})`,
                  subject: event.event.subject,
                  content: event.event.content,
                  priority: this.priority[event.event.priority],
                  type: this.types[event.event.type],
                  seen: event.seen ? {
                    value: this.$t('profile.events.seenTypes.seen'),
                    classes: 'text-success'
                  } : this.$t('profile.events.seenTypes.unSeen'),
                  createdAt: event.event.created_at,
                  eventData: event
                })
              })

              this.total_count = events.pagination.total
              this.page = events.pagination.current_page + 1

              if (events.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = events.pagination.total
              }

              if (this.$refs.eventsListTable) this.$refs.eventsListTable.loadMoreStatus = ''

            }).catch((error) => {
              if (this.$refs.eventsListTable && !axios.isCancel(error)) this.$refs.eventsListTable.loadMoreStatus = 'Danger'
            })
          } else {
            getProfileSentEvents(this.page, this.filters, this.sorts).then((response) => {
              const events = response.data

              events.data.forEach((event) => {
                this.data.push({
                  id: event.event.id,
                  rowNumber: this.data.length + 1,
                  receiver: `${event.user.name} ${event.user.family}(${event.user.role.name})`,
                  creator: `${event.event.creator.name} ${event.event.creator.family}(${event.event.creator.role.name})`,
                  subject: event.event.subject,
                  content: event.event.content,
                  priority: this.priority[event.event.priority],
                  type: this.types[event.event.type],
                  createdAt: event.event.created_at,
                  seen: event.seen ? {
                    value: this.$t('profile.events.seenTypes.seen'),
                    classes: 'text-success'
                  } : this.$t('profile.events.seenTypes.unSeen'),
                  eventData: event
                })
              })

              this.total_count = events.pagination.total
              this.page = events.pagination.current_page + 1

              if (events.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = events.pagination.total
              }

              if (this.$refs.eventsListTable) this.$refs.eventsListTable.loadMoreStatus = ''

            }).catch((error) => {
              if (this.$refs.eventsListTable && !axios.isCancel(error)) this.$refs.eventsListTable.loadMoreStatus = 'Danger'
            })
          }
        }
      }, 400)
    },
    showEvent (event) {
      this.selectedEvent = event.eventData
      setTimeout(() => {
        this.showEventStatus = true
        if (this.$refs.event) this.$refs.event.showEvent()
      }, 500)
    },
    setFilter (filters) {
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'priority':
          if (filters[key].search.value !== '-1') filters_list.priority = `${  filters[key].search.value}`
          break

        case 'type':
          if (filters[key].search.value !== '-1') filters_list.type = `${  filters[key].search.value}`
          break

        case 'seen':
          if (filters[key].search.value !== '-1') filters_list.seen = `${  filters[key].search.value}`
          break

        case 'content':
          if (filters[key].search !== '') filters_list.content = `${  filters[key].search}`
          break

        case 'subject':
          if (filters[key].search !== '') filters_list.subject = `${  filters[key].search}`
          break

        case 'receiver':
          if (filters[key].search !== '') filters_list.user = `${  filters[key].search}`
          break

        case 'creator':
          if (filters[key].search !== '') filters_list.creator = `${  filters[key].search}`
          break

        case 'createdAt':
          if (filters[key].search !== '') filters_list.created_at = `${  filters[key].search}`
          break
        }
      })

      this.data = []
      this.page = 1
      this.total_count = null
      this.filters = filters_list
      this.getEvents()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'priority':
          sorts_list.push(`order[0]=priority,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'seen':
          sorts_list.push(`order[0]=seen,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'content':
          sorts_list.push(`order[0]=content,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'receiver':
          sorts_list.push(`order[0]=user_id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'creator':
          sorts_list.push(`order[0]=creator_id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'createdAt':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.total_count = null
      this.sorts = sorts_list
      this.getEvents()
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.eventsChanged': {
      handler (val) {
        if (val) {
          this.insertEventPromptStatus = false

          this.data = []
          this.page = 1
          this.getEvents()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .events-list {
    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
